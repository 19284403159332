<template>
  <v-container fluid>
    <v-row dense class="">
      <v-col md="12">
        <div class="d-flex align-center gap-x-3">
          <FinancialsIcon class="" />
          <h3>Financial Reports</h3>
        </div>
      </v-col>
      <template v-for="(report, i) in reportList.financial">
        <v-col
          sm="12"
          md="4"
          lg="3"
          xl="3"
          :key="`financial_${i}`"
          v-if="
            checkReadPermission(
              $modules.reports[report.type]
                ? $modules.reports[report.type].slug
                : ''
            )
          "
        >
          <report-card v-bind="report" />
        </v-col>
      </template>
    </v-row>
    <v-row dense class="mt-6">
      <v-col md="12">
        <div class="d-flex align-center gap-x-3">
          <OperationalIcon class="" />
          <h3>Operational Reports</h3>
        </div>
      </v-col>
      <template v-for="(report, i) in reportList.operational">
        <v-col
          v-if="
            checkReadPermission(
              $modules.reports[report.type]
                ? $modules.reports[report.type].slug
                : ''
            )
          "
          sm="12"
          md="4"
          lg="3"
          xl="3"
          :key="`operational_${i}`"
        >
          <report-card v-bind="report" />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import ReportCard from "@/components/Reports/ReportCard";
import FinancialsIcon from "@/assets/images/reporting/finacials.svg";
import OperationalIcon from "@/assets/images/reporting/operational.svg";

export default {
  components: { ReportCard, FinancialsIcon, OperationalIcon },
  data() {
    return {
      reportList: {
        financial: [
          {
            id: 1,
            name: "Cashier Sales Report",
            route: "CashierReport",
            type: "cashier",
            color: "#04B1B0",
          },
          {
            id: 2,
            name: "List Bill Report",
            route: "ListBillReport",
            type: "bills",
            color: "#04B1B0",
          },
          {
            id: 3,
            name: "Payment Sales Report",
            route: "PaymentTypeSalesReport",
            type: "payment",
            color: "#04B1B0",
          },
          {
            id: 4,
            name: "Discount Sales Report",
            route: "DiscountReport",
            type: "discount",
            color: "#04B1B0",
          },
          {
            id: 10,
            name: "Open Invoice Report",
            route: "OpenInvoiceReport",
            type: "invoice",
            color: "#04B1B0",
          },
          {
            id: 11,
            name: "Online Sales Settlement Report",
            route: "OnlineSalesSettlementReport",
            type: "payment",
            color: "#04B1B0",
          },
          {
            id: 12,
            name: "Cashier Audit Report",
            route: "CashierAuditReport",
            type: "cashier_audit",
            color: "#04B1B0",
          },
          {
            id: 13,
            name: "Qube Sales Report",
            route: "WebsiteOnlineReport",
            type: "website_sales",
            color: "#04B1B0",
          },
          {
            id: 14,
            name: "Membership Report",
            route: "MembershipSalesReport",
            type: "membership_sales",
            color: "#04B1B0",
          },
          {
            id: 15,
            name: "Sales log Report",
            route: "SalesLogReport",
            type: "sales_log",
            color: "#04B1B0",
          },
        ],
        operational: [
          {
            id: 5,
            name: "Service Report",
            route: "VenueServiceReport",
            type: "service",
            color: "#062b48",
          },
          {
            id: 6,
            name: "Product Sales Report",
            route: "ProductSalesReport",
            type: "product",
            color: "#062b48",
          },
          {
            id: 7,
            name: "Facility Usage Report",
            route: "FacilityUsageReport",
            type: "facility",
            color: "#062b48",
          },
          {
            id: 8,
            name: "Attendance Report",
            route: "AttendanceReport",
            type: "attendance",
            color: "#062b48",
          },
          {
            id: 9,
            name: "Trainer Performance Report",
            route: "TrainerPerformanceReport",
            type: "trainer",
            color: "#062b48",
          },
          {
            id: 10,
            name: "Program Report",
            route: "ProgramReport",
            type: "programs",
            color: "#062b48",
          },
          {
            id: 11,
            name: "Expiring Package Report",
            route: "ExpiringPackageReport",
            type: "expiring_packages",
            color: "#062b48",
          },
          {
            id: 12,
            name: "Customer Demographic Report",
            route: "CustomerDemographicReport",
            type: "customer_demographic",
            color: "#062b48",
          },
          {
            id: 13,
            name: "Visitation Report",
            route: "VisitationReport",
            type: "b2b",
            color: "#062b48",
          },
          {
            id: 14,
            name: "Partners Report",
            route: "B2BReport",
            type: "b2b",
            color: "#062b48",
          },
          {
            id: 15,
            name: "Anonymous Visitation Report",
            route: "anonymous-visitation",
            type: "anonymous_visitation",
            color: "#062b48",
          },
          {
            id: 16,
            name: "Consolidated Visitation Analysis Report",
            route: "VisitationCountReport",
            type: "visitation_analysis",
            color: "#062b48",
          },
          {
            id: 17,
            name: "User Audit Report",
            route: "UserAuditReport",
            type: "user_audit",
            color: "#062b48",
          },
          {
            id: 18,
            name: "System Log Report",
            route: "SystemLogReport",
            type: "system_log",
            color: "#062b48",
          },
          {
            id: 19,
            name: "Occupancy Rate Report",
            route: "OccupancyRateReport",
            type: "occupancy_rate",
            color: "#062b48",
          },
          {
            id: 20,
            name: "Membership Installment Report",
            route: "MembershipInstallmentReport",
            type: "membership_installment",
            color: "#062b48",
          },
          {
            id: 21,
            name: "Customer Visitation Log",
            route: "CustomerVisitationReport",
            type: "customer_visitation",
            color: "#062b48",
          },
        ],
      },
    };
  },

  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
