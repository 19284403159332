<template>

  <div class="d-flex justify-center p-4 report_card " style="border: 1px solid #DCDCDC ; height: 70px ; background-color: #FFFFFF ; cursor: pointer" @click="gotoReports">
    <div class="d-flex gap-x-4 align-center" >
      <p class="font-bold text-base  blue-text p-0 m-0 ">{{name}}</p>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    id: { type: Number },
    route: { type: String, default: "" },
    name: { type: String, default: "" },
    type: { type: String },
    color: { type: String },
    url: { type: String },
  },
  methods: {
    gotoReports() {
      this.$router.push({
        name: this.route,
      });
    },
  },
};
</script>

<style scoped>
.card {
  font-weight: normal;
  height: 100px;
}


.report_card:hover{
  border: 1px solid rgba(17, 42, 70, 1);
  box-shadow: 0 8px 24px 0 rgba(70, 76, 136, 0.20) !important
}




</style>
