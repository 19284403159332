var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex align-center gap-x-3"},[_c('FinancialsIcon',{}),_c('h3',[_vm._v("Financial Reports")])],1)]),_vm._l((_vm.reportList.financial),function(report,i){return [(
          _vm.checkReadPermission(
            _vm.$modules.reports[report.type]
              ? _vm.$modules.reports[report.type].slug
              : ''
          )
        )?_c('v-col',{key:("financial_" + i),attrs:{"sm":"12","md":"4","lg":"3","xl":"3"}},[_c('report-card',_vm._b({},'report-card',report,false))],1):_vm._e()]})],2),_c('v-row',{staticClass:"mt-6",attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex align-center gap-x-3"},[_c('OperationalIcon',{}),_c('h3',[_vm._v("Operational Reports")])],1)]),_vm._l((_vm.reportList.operational),function(report,i){return [(
          _vm.checkReadPermission(
            _vm.$modules.reports[report.type]
              ? _vm.$modules.reports[report.type].slug
              : ''
          )
        )?_c('v-col',{key:("operational_" + i),attrs:{"sm":"12","md":"4","lg":"3","xl":"3"}},[_c('report-card',_vm._b({},'report-card',report,false))],1):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }